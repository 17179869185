import {
  Box,
  Button,
  Dialog,
  Divider,
  Flex,
  Modal,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconArrowLeft, IconBulb, IconCalendarCog } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { editCalendarCell, fetchCalenderData } from 'api/shift';
import ShiftCalenderTable from 'components/ShiftCalender/ShiftCalenderTable';
import UploadCalendar from 'components/ShiftCalender/UploadCalendar';
import ServerErrorBox from 'components/global/ServerErrorBox';
import COLORS from 'constants/colors';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationUtil } from 'utils/notifications';
import { handleErrorResponse } from 'utils/utils';
import LoadingCalendar from '../../assets/calendar_loding.gif';
import { updateCalendarData } from './updateCalendarResponse';

const ShiftCalender = () => {
  const navigate = useNavigate();

  const current_year = dayjs().format('YYYY');

  useEffect(() => {
    document.title = 'Shift Calendar | Montage';
  }, []);

  const [showTipsDialogue, setShowTipsDialogue] = useState(true);

  const [showUploadModal, setShowUploadModal] = useState(false);

  const [selectedCell, setSelectedCell] = useState(null);
  const [selectedNewValue, setSelectedNewValue] = useState(null);

  const [shiftCalendarData, setShiftCalendarData] = useState(null);

  const handleCellSelect = (
    groupIndex,
    dayIndex,
    month,
    date,
    status,
    groupName,
  ) => {
    setSelectedNewValue(null);
    const obj = {
      groupIndex,
      dayIndex,
      month,
      date,
      status,
      groupName,
    };
    setSelectedCell(obj);
  };

  //edit calendar inplace api call

  const saveInPlaceEdit = async () => {
    if (selectedCell) {
      const reqBody = {
        date: selectedCell?.date,
        groupName: selectedCell?.groupName,
        status: selectedNewValue,
      };
      ConfirmSaveModal(reqBody);
    }
  };

  const ConfirmSaveModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you want to save the change?</Text>
      ),
      confirmProps: { color: 'blue' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        editMutate(values);
      },
    });
  };

  const { data, isLoading, refetch, error, isFetching } = useQuery({
    queryKey: ['shift-calender'],
    queryFn: async (values) => await fetchCalenderData(values),
    onSuccess: (response) => {
      if (response?.data?.status == 200) {
        setShiftCalendarData(response?.data?.data);
      }
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const { mutate: editMutate, isLoading: isEditing } = useMutation({
    mutationFn: async (values) => await editCalendarCell(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Calendar Updated!',
        message: 'Calendar Updated Successfully!',
      });
      setSelectedCell(null);
      setSelectedNewValue(null);
      // refetch();
      const updatedData = updateCalendarData(
        shiftCalendarData,
        selectedCell?.date,
        selectedCell?.groupName,
        selectedNewValue,
        selectedCell?.month,
      );
      setShiftCalendarData(updatedData);
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error?.response?.data?.message,
      });
    },
  });

  if (isLoading || isFetching)
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            Shift Calendar
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            radius="xs"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <Stack
          justify="center"
          align="center"
          sx={{
            height: '85vh',
            backgroundColor: '#fff',
            marginTop: '1em',
          }}>
          <Text size="lg">Loading Calendar...</Text>
          <Box py="sm">
            <img src={LoadingCalendar} alt="LoadingCalendar" />
          </Box>
          <Text size="md">
            It may take some time to load this page. Please wait a moment.
          </Text>
        </Stack>
      </div>
    );

  if (error)
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            Shift Calender
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            radius="xs"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const { data: calenderData } = data.data;

  return (
    <div>
      {/* Upload File Modal */}
      <Modal
        opened={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        title={<Text fw={600}>Upload Calendar</Text>}
        size="auto"
        closeOnClickOutside={false}
        centered={true}>
        <UploadCalendar
          onUpdate={() => {
            refetch();
            setShowUploadModal(false);
          }}
        />
      </Modal>
      {/* Upload File Modal END */}
      <Flex justify="space-between" align="center">
        <Title order={1} size="h2">
          Shift Calendar
        </Title>
        <Flex align="center" gap={5}>
          <Button
            onClick={() => setShowUploadModal(true)}
            sx={{
              backgroundColor: COLORS.primary,
            }}
            color="#e1e1e1"
            radius="xs"
            leftIcon={<IconCalendarCog />}>
            Update Calendar
          </Button>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            radius="xs"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
      </Flex>
      <Text fw={700} fz="xl" align="center" py="xs">
        CALENDAR FOR CONTINUOUS OPERATIONS | {current_year}
      </Text>
      <Stack align="end" py="sm">
        <Flex
          align="center"
          justify="center"
          gap={10}
          sx={{
            border: '2px solid #e1e1e1',
          }}>
          <Flex align="center" gap={5} p={2} pl={10} justify="center">
            <Text fw={600}>M</Text>
            <Text fw={500}>-</Text>
            <Text>Morning</Text>
          </Flex>
          <Divider orientation="vertical" size="md" />
          <Flex align="center" gap={5} p={2} justify="center">
            <Text fw={600}>E</Text>
            <Text fw={500}>-</Text>
            <Text>Evening</Text>
          </Flex>
          <Divider orientation="vertical" size="md" />
          <Flex align="center" gap={5} p={2} justify="center">
            <Text fw={600}>N</Text>
            <Text fw={500}>-</Text>
            <Text>Night</Text>
          </Flex>
          <Divider orientation="vertical" size="md" />
          <Flex align="center" gap={5} p={2} justify="center">
            <Text fw={600}>R</Text>
            <Text fw={500}>-</Text>
            <Text>Rest</Text>
          </Flex>
          <Divider orientation="vertical" size="md" />
          <Flex align="center" gap={5} p={2} justify="center" pr={10}>
            <Text fw={600}>HD</Text>
            <Text fw={500}>-</Text>
            <Text>Holiday</Text>
          </Flex>
        </Flex>
      </Stack>
      <Flex direction="column" gap={50} mb="3em">
        {shiftCalendarData &&
          shiftCalendarData?.map((monthTable, index) => (
            <ShiftCalenderTable
              data={monthTable}
              tableIndex={index}
              key={index}
              setSelectedCell={setSelectedCell}
              selectedCell={selectedCell}
              handleCellSelect={handleCellSelect}
              setSelectedNewValue={setSelectedNewValue}
              selectedNewValue={selectedNewValue}
              saveInPlaceEdit={saveInPlaceEdit}
              isEditing={isEditing}
            />
          ))}
      </Flex>
      <Dialog
        opened={showTipsDialogue}
        withCloseButton
        onClose={() => setShowTipsDialogue(false)}
        size="lg"
        radius="sm">
        <Flex justify="flex-start" align="center" gap={10}>
          <IconBulb color="red" />
          <Text size="1.3rem" weight={600}>
            Quick Tips
          </Text>
        </Flex>

        <Text size="sm" m="xs" weight={600}>
          Double Click on any cell to update!
        </Text>
      </Dialog>
    </div>
  );
};

export default ShiftCalender;
