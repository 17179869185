import {
  IconAdjustments,
  IconChartHistogram,
  IconCheckupList,
  IconClipboardData,
  IconClipboardText,
  IconLayoutDashboard,
} from '@tabler/icons-react';

const navBarList = [
  {
    label: 'Dashboard',
    disabled: false,
    icon: IconLayoutDashboard,
    link: '/dashboard',
  },
  // {
  //   label: 'SKUs',
  //   disabled: false,
  //   icon: IconBrandFlipboard,
  //   link: '/skus',
  // },
  // {
  //   label: 'Materials',
  //   disabled: false,
  //   icon: IconCylinder,
  //   link: '/materials',
  // },
  // {
  //   label: 'Containers',
  //   disabled: false,
  //   icon: IconBoxModel2,
  //   link: '/containers',
  // },
  // {
  //   label: 'Machines',
  //   disabled: false,
  //   icon: IconHexagons,
  //   link: '/machines',
  // },

  // {
  //   label: 'Users',
  //   disabled: false,
  //   icon: IconUser,
  //   link: '/users',
  // },
  // {
  //   label: 'Blends',
  //   disabled: false,
  //   icon: IconLeaf,
  //   link: '/blends',
  // },
  // {
  //   label: 'Action Logs',
  //   disabled: false,
  //   icon: IconArticle,
  //   link: '/action-logs',
  // },
  {
    label: 'Event Logs',
    disabled: false,
    icon: IconClipboardData,
    link: '/event-logs',
  },
  {
    label: 'Container Logs',
    disabled: false,
    icon: IconClipboardText,
    link: '/container-logs',
  },
  {
    label: 'Cross Check Material',
    disabled: true,
    icon: IconCheckupList,
    link: '/cross-check-material',
  },
  {
    label: 'Settings',
    disabled: false,
    icon: IconAdjustments,
    link: '/settings',
  },
  {
    label: 'Reports',
    disabled: false,
    icon: IconChartHistogram,
    link: '/reports',
  },
];

export default navBarList;
