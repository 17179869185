const COLORS = {
  primary: '#0e2b63',
  secondary: '#5a328a',
  tertiary: '#004f9f',
  blue: '#00b1eb',
  orange: '#ef7d00',
  yellow: '#ffbb00',
  green: '#50af47',
  lightGreen: '#afca0b',
  redMatt: '#EB455F',
  pink: '#e72582',
  white: '#FFFFFF',
  black: '#171718',
  gray: '#e7e7e7',
  darkGray: '#5A5A5A',
  grayish: '#f7f7f7',
  info: '#4562f7',
  success: '#7FB77E',
  error: '#FF5858',
  warning: '#F1A661',
  'SUPER ADMIN': '#FF5858',
  ADMIN: '#865DFF',
  MANAGER: '#7FB77E',
  'GODOWN MANAGER': 'rgb(253, 126, 20)',
  OPERATOR: '#404258',
  fontSecondary: '#67748E',
};

export default COLORS;
