const GRADIENTS = {
  darkblue: '#1e3c72',
  blue: '#005c97',
  pink: '#b24592',
  green: '#198754',
  orange: '#f7971e',
  lightgreen: '#76b852',
  skyblue: '#2193b0',
  purple: '#61045f',
  red: '#dc3545',
  white: '#ffffff',
  yellow: '#ffc107',
};

export default GRADIENTS;
