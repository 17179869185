import { Stack } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import NavBarItem from './NavBarItem';
import navBarList from './NavBarList';
import useStyles from './style';

const NavBarItems = () => {
  const classes = useStyles();

  const location = useLocation();

  return (
    <Stack justify="center" align="center" spacing="lg">
      <div className={classes.linksInner}>
        {navBarList.map((item, index) => (
          <div
            key={index}
            style={{
              marginBottom: 15,
            }}>
            <NavBarItem
              active={location.pathname
                .split('/')
                .includes(item.link?.split('/')[1])}
              icon={item.icon}
              label={item.label}
              // onClick={() => navigate(item.link)}
              link={item.link}
            />
          </div>
        ))}
      </div>
    </Stack>
  );
};

export default NavBarItems;
