import {
  AppShell,
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Footer,
  Group,
  HoverCard,
  Navbar,
  ScrollArea,
  Space,
  Stack,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { closeAllModals, openConfirmModal } from '@mantine/modals';
import { IconLogout, IconPhone, IconMail } from '@tabler/icons-react';
import { AuthContext } from 'context/auth-context';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { getInitialFromName } from 'utils/utils';
import CustomFooter from '../components/global/CustomFooter';
import NavBarItem from './NavBar/NavBarItem';
import navBarList from './NavBar/NavBarList';

const noLayoutRoutes = ['/', '/download-app', '/forgot-password'];

import useStyles from './style';
import COLORS from 'constants/colors';
import NavBarItems from './NavBar/NavBarItems';

const Layout = ({ children }) => {
  const location = useLocation();

  const { classes } = useStyles();

  const { user, logout } = useContext(AuthContext);

  //returns view without navbar
  if (noLayoutRoutes.includes(location.pathname)) {
    return (
      <AppShell
        footer={
          <Footer fixed>
            <CustomFooter />
          </Footer>
        }>
        {children}
      </AppShell>
    );
  }

  const openLogoutConfirmModal = () => {
    openConfirmModal({
      title: 'Are you sure?',
      children: <Text size="sm">Are you sure you want to logout?</Text>,
      labels: {
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
      onCancel: () => {
        closeAllModals();
      },
      onConfirm: () => {
        closeAllModals();
        logout();
      },
    });
  };

  //returns view with navbar
  return (
    <AppShell
      className={classes.appShell}
      navbar={
        <Navbar
          width={{ base: 80 }}
          height="100vh"
          p="md"
          sx={{
            backgroundColor: COLORS.primary,
          }}>
          <Center>
            <HoverCard
              shadow="md"
              position="right"
              withArrow
              arrowPosition="side"
              sx={{
                maxWidth: 300,
              }}>
              <HoverCard.Target>
                <UnstyledButton>
                  <Avatar color={COLORS.white} src={user?.avatar}>
                    {getInitialFromName(user?.name)}
                  </Avatar>
                </UnstyledButton>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Stack>
                  <Stack spacing={0}>
                    <Flex gap={10} align="center" wrap="wrap">
                      <Title order={4}>{user?.name}</Title>
                    </Flex>
                  </Stack>
                  <Flex gap={5}>
                    <IconMail size={20} />
                    <Text size="sm">{user?.email}</Text>
                  </Flex>
                  <Flex gap={5}>
                    <IconPhone size={20} />
                    <Text size="sm">{user?.phone}</Text>
                  </Flex>

                  <Flex gap={5}>
                    <Badge variant="filled">{user?.role}</Badge>
                  </Flex>
                </Stack>
              </HoverCard.Dropdown>
            </HoverCard>
          </Center>
          <Navbar.Section grow mt={50}>
            <div className={classes.linksInner}>
              {navBarList.map((item, index) => (
                <NavBarItem key={index} {...item} />
              ))}
            </div>
          </Navbar.Section>
          <Navbar.Section className={classes.navFooter}>
            <div>
              <Tooltip label={'Logout'} position="right">
                <UnstyledButton
                  onClick={() => openLogoutConfirmModal()}
                  className={classes.control}>
                  <Group position="apart" spacing={0}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <ThemeIcon variant="filled" size={45} color="red">
                        <IconLogout size="1.5rem" stroke={1.5} />
                      </ThemeIcon>
                    </Box>
                  </Group>
                </UnstyledButton>
              </Tooltip>
            </div>
          </Navbar.Section>
        </Navbar>
      }
      footer={
        <Footer
          fixed
          sx={{
            borderTop: 'none',
          }}>
          <CustomFooter />
        </Footer>
      }>
      {children}
    </AppShell>
  );
};

export default Layout;
