import ProgressLoader from 'components/ProgressLoader/ProgressLoader';
import {
  ACTION_LOGS_PAGE_URL,
  APP_DISTRIBUTION_PAGE_URL,
  BLEND_AND_FILTER_PAGE_URL,
  CONTAINER_PAGE_URL,
  MACHINE_PAGE_URL,
  MATERIAL_PAGE_URL,
  REPORTS_FMD_URL,
  REPORTS_PMD_URL,
  SHIFT_CALENDER_PAGE_URL,
  SKU_PAGE_URL,
} from 'constants/urls';
import { AuthContext } from 'context/auth-context';
import withProtection from 'hoc/withProtection';
import Layout from 'layout/Layout';
import nProgress from 'nprogress';
import FourOFour from 'pages/404/FourOFour';
import DownloadApp from 'pages/DownloadApp/DownloadApp';
import MobileApplications from 'pages/MobileApplications/MobileApplications';
import FmdReports from 'pages/Reports/details/FmdReports';
import PmdReports from 'pages/Reports/details/PmdReports';
import ShiftCalender from 'pages/ShiftCalender/ShiftCalender';
import React, { Suspense, useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

//lazy load pages
const SignIn = React.lazy(() => import('pages/SignIn/SignIn'));
const Dashboard = React.lazy(() => import('pages/Dashboard/Dashboard'));
const Users = React.lazy(() => import('pages/Users/Users'));
const Skus = React.lazy(() => import('pages/Skus/Skus'));
const SkuDetails = React.lazy(() => import('pages/Skus/SkuDetails'));
const Materials = React.lazy(() => import('pages/Materials/Materials'));
const CrossCheckMaterial = React.lazy(
  () => import('pages/CrossCheckMaterial/CrossCheckMaterial'),
);
const ForgotPassword = React.lazy(
  () => import('pages/ForgotPassword/ForgotPassword'),
);
const Makers = React.lazy(() => import('pages/Makers/Makers'));
const Machines = React.lazy(() => import('pages/Machines/Machines'));
const MachineDetails = React.lazy(
  () => import('pages/MachineDetails/MachineDetails'),
);

const Container = React.lazy(() => import('pages/Container/Container'));
const EventLogs = React.lazy(() => import('pages/EventLogs/EventLogs'));
const ActionLogs = React.lazy(() => import('pages/ActionLogs/ActionLogs'));
const Reports = React.lazy(() => import('pages/Reports/Reports'));
const ContainerLogs = React.lazy(
  () => import('pages/ContainerLogs/ContainerLogs'),
);
const Settings = React.lazy(() => import('pages/Settings/Settings'));
const BlendsAndFilters = React.lazy(
  () => import('pages/BlendsAndFilters/BlendsAndFilters'),
);

const App = () => {
  const location = useLocation();

  const { isLoggedIn, appLoaded, user } = useContext(AuthContext);
  //console.log(user);

  // This is for React Router Route Changes
  useEffect(() => {
    nProgress.start();
    nProgress.done();
  }, [location.pathname]);

  return (
    appLoaded && (
      <Layout>
        <Suspense fallback={<ProgressLoader />}>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route path="/download-app" element={<DownloadApp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/dashboard"
              element={withProtection(<Dashboard />, isLoggedIn)}
            />
            <Route
              path="/settings/users"
              element={withProtection(<Users />, isLoggedIn)}
            />
            <Route
              path={SKU_PAGE_URL}
              element={withProtection(<Skus />, isLoggedIn)}
            />
            <Route
              path="/settings/skus/details"
              element={withProtection(<SkuDetails />, isLoggedIn)}
            />
            <Route
              path={MATERIAL_PAGE_URL}
              element={withProtection(<Materials />, isLoggedIn)}
            />
            <Route
              path={CONTAINER_PAGE_URL}
              element={withProtection(<Container />, isLoggedIn)}
            />
            <Route
              path="/cross-check-material"
              element={withProtection(<CrossCheckMaterial />, isLoggedIn)}
            />
            <Route
              path="/reports"
              element={withProtection(<Reports />, isLoggedIn)}
            />
            <Route
              path={REPORTS_PMD_URL}
              element={withProtection(<PmdReports />, isLoggedIn)}
            />
            <Route
              path={REPORTS_FMD_URL}
              element={withProtection(<FmdReports />, isLoggedIn)}
            />
            <Route
              path="/makers"
              element={withProtection(<Makers />, isLoggedIn)}
            />
            {/* <Route
              path="/blends"
              element={withProtection(<Blends />, isLoggedIn)}
            /> */}
            <Route
              path={BLEND_AND_FILTER_PAGE_URL}
              element={withProtection(<BlendsAndFilters />, isLoggedIn)}
            />
            <Route
              path={MACHINE_PAGE_URL}
              element={withProtection(<Machines />, isLoggedIn)}
            />
            <Route
              path="/settings/machines/:machineUid"
              element={withProtection(<MachineDetails />, isLoggedIn)}
            />
            <Route
              path={ACTION_LOGS_PAGE_URL}
              element={withProtection(<ActionLogs />, isLoggedIn)}
            />
            <Route
              path="/event-logs"
              element={withProtection(<EventLogs />, isLoggedIn)}
            />
            <Route
              path="/container-logs"
              element={withProtection(<ContainerLogs />, isLoggedIn)}
            />
            <Route
              path="/settings"
              element={withProtection(<Settings />, isLoggedIn)}
            />
            <Route
              path={SHIFT_CALENDER_PAGE_URL}
              element={withProtection(<ShiftCalender />, isLoggedIn)}
            />
            <Route
              path={APP_DISTRIBUTION_PAGE_URL}
              element={withProtection(
                <MobileApplications />,
                isLoggedIn,
                true,
                user?.role,
              )}
            />
            <Route path="*" element={<FourOFour />} />
          </Routes>
        </Suspense>
      </Layout>
    )
  );
};

export default App;
