import { Button, Flex, Loader, Select, Table, Tooltip } from '@mantine/core';
import { IconDeviceFloppy, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { getCalendarGroupColor, isArrayAndHasContent } from 'utils/utils';
import './ShiftCalenderStyles.css';
import { useEffect } from 'react';

const ShiftCalenderTable = ({
  data,
  selectedCell,
  setSelectedCell,
  handleCellSelect,
  selectedNewValue,
  setSelectedNewValue,
  saveInPlaceEdit,
  isEditing,
}) => {
  // Parse the input date using Day.js
  const parsedDate = dayjs(data?.key, 'DD-MM-YYYY');

  const table_title = dayjs(parsedDate, 'YYYY-MM-DD').format('MMMM-YYYY');

  const daysInTheMonth = parsedDate.daysInMonth();

  // Get the month from the parsed date
  const month = parsedDate.month(); // Month is zero-based in JavaScript (January is 0, February is 1, and so on)

  // Create an array to store weekdays
  const weekdays = [];

  // Loop through the days of the month
  for (let day = 1; day <= parsedDate.daysInMonth(); day++) {
    const currentDate = parsedDate.date(day);

    // Get the first character of the weekday name for the current date
    const firstCharOfWeekday = currentDate.format('dd')[0];

    // Add the first character of the weekday name to the array
    weekdays.push(firstCharOfWeekday);
  }

  return (
    <div className="table_section">
      <Table style={{ borderColor: 'black' }}>
        <thead>
          <tr>
            <th colSpan="32" style={{ color: '#222' }}>
              {table_title}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="light_pink_boxes">
            <td>DATE</td>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td>5</td>
            <td>6</td>
            <td>7</td>
            <td>8</td>
            <td>9</td>
            <td>10</td>
            <td>11</td>
            <td>12</td>
            <td>13</td>
            <td>14</td>
            <td>15</td>
            <td>16</td>
            <td>17</td>
            <td>18</td>
            <td>19</td>
            <td>20</td>
            <td>21</td>
            <td>22</td>
            <td>23</td>
            <td>24</td>
            <td>25</td>
            <td>26</td>
            <td>27</td>
            <td>28</td>
            {daysInTheMonth >= 29 ? <td>29</td> : <td></td>}
            {daysInTheMonth >= 30 ? <td>30</td> : <td></td>}
            {daysInTheMonth == 31 ? <td>31</td> : <td></td>}
          </tr>
          <tr className="light_purple_boxes">
            <td>DAY</td>
            {weekdays.map((d, index) => {
              return <td key={index}>{d}</td>;
            })}
          </tr>

          {data?.groups?.map((group, groupIndex) => {
            const { groupData } = group;

            return (
              <tr
                key={groupIndex}
                style={{
                  backgroundColor: getCalendarGroupColor(group.groupName),
                }}>
                <td>{group.groupName}</td>
                {isArrayAndHasContent(groupData) &&
                  groupData.map((day, dayIndex) => {
                    if (day.status !== 'H') {
                      return (
                        <td
                          key={dayIndex}
                          onDoubleClick={() =>
                            handleCellSelect(
                              groupIndex,
                              dayIndex,
                              data?.month,
                              day?.date,
                              day?.status,
                              group.groupName,
                            )
                          }
                          style={{
                            backgroundColor:
                              day.status === 'R' ? '#fbd4b4' : '',
                            cursor: 'pointer',
                            maxWidth: '120px',
                          }}>
                          <div>
                            {selectedCell &&
                            selectedCell.groupIndex === groupIndex &&
                            selectedCell.dayIndex === dayIndex &&
                            selectedCell.month === data?.month ? (
                              <div style={{ position: 'relative' }}>
                                <Select
                                  value={
                                    selectedNewValue
                                      ? selectedNewValue
                                      : selectedCell.status
                                  }
                                  allowDeselect
                                  onChange={setSelectedNewValue}
                                  placeholder="Pick one"
                                  searchable
                                  data={[
                                    { value: 'M', label: 'Morning' },
                                    { value: 'E', label: 'Evening' },
                                    { value: 'N', label: 'Night' },
                                    { value: 'R', label: 'Rest' },
                                    { value: 'H', label: 'Holiday' },
                                  ]}
                                  size="xs"
                                />
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '35px',
                                    right: '0px',
                                    backgroundColor: '#fff',
                                    padding: '0.5em',
                                    boxShadow:
                                      'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                  }}>
                                  <Flex
                                    gap={10}
                                    align="center"
                                    justify="flex-end">
                                    {isEditing ? (
                                      <Tooltip label="Save">
                                        <Loader size="sm" variant="oval" />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip label="Save">
                                        <Button
                                          size="1em"
                                          disabled={!selectedNewValue}
                                          onClick={() => saveInPlaceEdit()}>
                                          <IconDeviceFloppy />
                                        </Button>
                                      </Tooltip>
                                    )}

                                    <Tooltip label="Cancel">
                                      <Button
                                        size="1em"
                                        color="red"
                                        disabled={isEditing}
                                        onClick={() => {
                                          setSelectedCell(null);
                                          setSelectedNewValue(null);
                                        }}>
                                        <IconX />
                                      </Button>
                                    </Tooltip>
                                  </Flex>
                                </div>
                              </div>
                            ) : (
                              day.status
                            )}
                          </div>
                        </td>
                      );
                    } else if (day.status === 'H') {
                      return (
                        <td
                          key={dayIndex}
                          onDoubleClick={() =>
                            handleCellSelect(
                              groupIndex,
                              dayIndex,
                              data?.month,
                              day?.date,
                              day?.status,
                              group.groupName,
                            )
                          }
                          style={{
                            backgroundColor: '#fff',
                            color: 'red',
                            cursor: 'pointer',
                            maxWidth: '120px',
                          }}>
                          <div>
                            {selectedCell &&
                            selectedCell.groupIndex === groupIndex &&
                            selectedCell.dayIndex === dayIndex &&
                            selectedCell.month === data?.month ? (
                              <div style={{ position: 'relative' }}>
                                <Select
                                  value={
                                    selectedNewValue
                                      ? selectedNewValue
                                      : selectedCell.status
                                  }
                                  onChange={setSelectedNewValue}
                                  placeholder="Pick one"
                                  searchable
                                  data={[
                                    { value: 'M', label: 'Morning' },
                                    { value: 'E', label: 'Evening' },
                                    { value: 'N', label: 'Night' },
                                    { value: 'R', label: 'Rest' },
                                    { value: 'H', label: 'Holiday' },
                                  ]}
                                  size="xs"
                                />
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '35px',
                                    right: '0px',
                                    backgroundColor: '#fff',
                                    padding: '0.5em',
                                    boxShadow:
                                      'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                  }}>
                                  <Flex
                                    gap={10}
                                    align="center"
                                    justify="flex-end">
                                    {isEditing ? (
                                      <Tooltip label="Saving">
                                        <Loader size="sm" variant="oval" />
                                      </Tooltip>
                                    ) : (
                                      <Tooltip label="Save">
                                        <Button
                                          size="1em"
                                          disabled={!selectedNewValue}
                                          onClick={() => saveInPlaceEdit()}>
                                          <IconDeviceFloppy />
                                        </Button>
                                      </Tooltip>
                                    )}

                                    <Tooltip label="Cancel">
                                      <Button
                                        size="1em"
                                        color="red"
                                        disabled={isEditing}
                                        onClick={() => {
                                          setSelectedCell(null);
                                          setSelectedNewValue(null);
                                        }}>
                                        <IconX />
                                      </Button>
                                    </Tooltip>
                                  </Flex>
                                </div>
                              </div>
                            ) : (
                              <>HD</>
                            )}
                          </div>
                        </td>
                      );
                    }
                  })}

                {!groupData &&
                  Array.from({ length: daysInTheMonth }, (_, index) => (
                    <td key={index}></td>
                  ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ShiftCalenderTable;
