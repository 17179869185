import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import { BrowserRouter } from 'react-router-dom';
import MantineWrapper from 'Wrappers/MantineWrapper';
import QueryWrapper from 'Wrappers/QueryWrapper';
import ReduxWrapper from 'Wrappers/ReduxWrapper';

import './index.css';
import './nprogress.css';
import { AuthContextProvider } from 'context/auth-context';
import IdleWrapper from 'Wrappers/idleWrapper';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ReduxWrapper>
      <BrowserRouter>
        <QueryWrapper>
          <AuthContextProvider>
            <MantineWrapper>
              <IdleWrapper>
                <App />
              </IdleWrapper>
            </MantineWrapper>
          </AuthContextProvider>
        </QueryWrapper>
      </BrowserRouter>
    </ReduxWrapper>
  </React.StrictMode>,
);
