import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Text,
  createStyles,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconDownload, IconFileUpload, IconX } from '@tabler/icons-react';
import COLORS from 'constants/colors';
import React from 'react';
import FileGif from '../../assets/file.gif';

const useStyles = createStyles(() => ({
  confirmUploaBox: {
    minWidth: '150px',
    cursor: 'pointer',
    borderRadius: '8px',
    fontWeight: '600',
  },
}));

const DisclaimerPanel = ({ uploadFile, selectedFile, fileName, onUpdate }) => {
  const { classes } = useStyles();

  const handleCancel = () => {
    ConfirmModalForCancel();
  };

  const ConfirmModalForCancel = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Flex direction="column" gap={10}>
          <Text size="sm">Are you sure you want to cancel?</Text>
          <Text size="xs" color="red" fw={600}>
            All your saved data will be lost!
          </Text>
        </Flex>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        onUpdate();
      },
    });
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Grid style={{ height: '100%', width: '100%' }} p="md">
        <Grid.Col lg={6} md={6} sm={12} xs={12} s>
          <Flex gap={5} direction="column" p="sm" justify="space-between">
            <div>
              <Text fz="xl" fw={700} color={COLORS.fontPrimary} py="sm">
                Please read this before you upload!
              </Text>

              <Text fz="sm" fw={600} color={COLORS.fontSecondary}>
                For successful calendar change please make sure you read all the
                after effects which it may cause.
              </Text>

              <Flex justify="flex-start" align="center">
                <ul
                  style={{
                    color: COLORS.fontPrimary,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                  }}>
                  <li>All previous calendar data will be lost.</li>
                  <li>
                    All previous assigned schedules for each machine will be
                    lost and it will be changed with new calendar schedules.
                  </li>
                  <li>All the data related to schedules will be updated.</li>
                </ul>
              </Flex>
            </div>
          </Flex>

          <Flex mt="sm" direction="column" p="sm" justify="space-between">
            <div>
              <p style={{ fontWeight: '600', color: 'red' }}>
                *** Please read carefully before doing this operation!
              </p>

              <p style={{ fontWeight: '600', color: 'red' }}>
                This operation cannot be undone after being executed. Please
                proceed with caution.
              </p>
            </div>
          </Flex>
        </Grid.Col>
        <Grid.Col lg={6} md={6} sm={12} xs={12}>
          <Flex
            direction="column"
            gap={10}
            justify="flex-start"
            align="center"
            style={{ height: '100%', paddingTop: '5em' }}>
            <div>
              <img src={FileGif} alt="select-file" height="150px" />
            </div>

            <Text py="sm" color="blue" p="sm" align="center">
              {fileName}
            </Text>

            <Flex gap={5}>
              <Button
                className={classes.confirmUploaBox}
                sx={{ backgroundColor: COLORS.primary }}
                onClick={() => uploadFile(selectedFile)}
                size="lg"
                leftIcon={<IconFileUpload size="2rem" />}>
                Confirm Upload
              </Button>
              <Button
                className={classes.confirmUploaBox}
                color="red"
                onClick={() => handleCancel()}
                size="lg"
                leftIcon={<IconX size="2rem" />}>
                Cancel
              </Button>
            </Flex>
          </Flex>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default DisclaimerPanel;
