import { notifications } from '@mantine/notifications';

import { Dialog, Group, Button, TextInput, Text } from '@mantine/core';

export const NotificationUtil = ({
  success,
  successColor = 'green',
  title,
  message,
}) => {
  notifications.show({
    color: success ? successColor : 'red',
    title: title || 'Error',
    message: message || 'Something went wrong!',
  });
};
