import axios from 'api/axiosInstance';
import { API_ROUTE_LOGS } from 'constants/apiRoutes';

export const fetchAllEventLogs = async ({
  page,
  searchKey,
  pageless,
  pageSize = 10,
  actionType,
  status,
  machineId,
  startDate,
  endDate,
  type = null,
  pageLess,
}) => {
  const response = await axios.get(`${API_ROUTE_LOGS}/events`, {
    params: {
      page: page,
      searchKey: searchKey,
      pageless: pageless,
      pageSize: pageSize,
      actionType: actionType,
      status: status,
      machineId: machineId,
      startDate,
      endDate,
      type,
      pageLess,
    },
  });

  return {
    eventLogs: response?.data.data.eventLogs,
    total: parseInt(response?.data.data.totalEntries),
  };
};

export const fetchEventLogDetails = async (values) => {
  const response = await axios.get(`${API_ROUTE_LOGS}/event/${values.uid}`);

  return response.data.data.eventLog;
};

export const fetchAllContainerLogs = async ({
  page,
  searchKey,
  pageless,
  pageSize = 10,
  actionType,
  status,
  machineUid,
  startDate,
  endDate,
  containerType,
  type = null,
  pageLess,
}) => {
  const response = await axios.get(`${API_ROUTE_LOGS}/containers`, {
    params: {
      page: page,
      searchKey: searchKey,
      pageless: pageless,
      pageSize: pageSize,
      actionType: actionType,
      status: status,
      machineUid: machineUid,
      startDate,
      endDate,
      containerType,
      type,
      pageLess,
    },
  });

  return {
    containerLogs: response?.data.data.containerLogs,
    total: parseInt(response?.data.data.totalEntries),
  };
};

export const fetchContainerLogDetails = async (values) => {
  const response = await axios.get(`${API_ROUTE_LOGS}/container/${values.uid}`);

  return response.data.data.containerLog;
};

export const fetchAllActionLogs = async ({
  page,
  searchKey,
  pageless,
  pageSize = 10,
  operatorUid,
  actionType,
  status,
}) => {
  const response = await axios.get(`${API_ROUTE_LOGS}/actions`, {
    params: {
      page: page,
      searchKey: searchKey,
      pageless: pageless,
      pageSize: pageSize,
      operatorUid,
      actionType,
      status,
    },
  });

  return {
    actionLogs: response?.data.data.actionLogs,
    total: parseInt(response?.data.data.totalEntries),
  };
};
