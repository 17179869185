export function updateCalendarData(list, date, groupName, status, month) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].month == month && list[i].groups) {
      for (let j = 0; j < list[i].groups.length; j++) {
        if (list[i].groups[j].groupName === groupName) {
          const groupData = list[i].groups[j].groupData;
          for (let k = 0; k < groupData.length; k++) {
            if (groupData[k].date === date) {
              groupData[k].status = status;
            }
          }
        }
      }
    }
  }
  return list; // Return false if no matching object is found
}
