const {
  API_ROUTE_REPORTS_PMD_LOAD,
  API_ROUTE_REPORTS_PMD_UNLOAD,
} = require('constants/apiRoutes');
import axios from 'api/axiosInstance';

export const exportPmdReportLoad = async ({
  page,
  searchKey,
  pageless,
  pageSize = 10,
  actionType,
  status,
  machineUid,
  startDate,
  endDate,
  type = null,
  pageLess,
}) => {
  const response = await axios.get(API_ROUTE_REPORTS_PMD_LOAD, {
    params: {
      page: page,
      searchKey: searchKey,
      pageless: pageless,
      pageSize: pageSize,
      actionType: actionType,
      status: status,
      machineUid: machineUid,
      startDate,
      endDate,
      type,
      pageLess,
    },
  });

  return response;
};

export const exportPmdReportUnload = async ({
  page,
  searchKey,
  pageless,
  pageSize = 10,
  actionType,
  status,
  machineUid,
  startDate,
  endDate,
  containerType,
  type = null,
  pageLess,
}) => {
  const response = await axios.get(API_ROUTE_REPORTS_PMD_UNLOAD, {
    params: {
      page: page,
      searchKey: searchKey,
      pageless: pageless,
      pageSize: pageSize,
      actionType: actionType,
      status: status,
      machineUid: machineUid,
      startDate,
      endDate,
      containerType,
      type,
      pageLess,
    },
  });

  return response;
};

export const removeExportFile = async (fileName) => {
  const response = await axios.delete('/reports/remove/file', {
    params: {
      fileName,
    },
  });
  return response;
};
