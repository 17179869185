import { Button, Flex, Stack } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import COLORS from 'constants/colors';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const InnerPage = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Stack>
      <Flex justify="end">
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          radius="xs"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>
      {children}
    </Stack>
  );
};

export default InnerPage;
