import { Button, Flex, Paper, Tabs, Text, Title } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import PmdReportLoadTab from 'components/Reports/PmdReportsTabs/PmdReportLoadTab';
import PmdReportUnloadTab from 'components/Reports/PmdReportsTabs/PmdReportUnloadTab';
import COLORS from 'constants/colors';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const PAGE_TITLE = 'PMD Reports';

const tabs = [
  { label: 'Load', value: 'load' },
  { label: 'Unload', value: 'unload' },
];

const PmdReports = () => {
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState('load');

  useEffect(() => {
    document.title = 'PMD Reports | Montage';
  }, []);

  return (
    <div>
      <Flex justify="space-between" align="center">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          radius="xs"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Tabs value={currentTab} onTabChange={setCurrentTab} variant="outline">
          <Tabs.List position="left">
            {tabs.map((tab, index) => (
              <Tabs.Tab
                value={tab.value}
                key={tab.value}
                sx={{
                  backgroundColor:
                    currentTab === tab.value ? COLORS.primary : COLORS.white,
                  color: currentTab === tab.value ? COLORS.white : COLORS.black,
                  backgroundImage:
                    currentTab === tab.value ? COLORS.accent : COLORS.white,
                  minWidth: '250px',
                }}>
                <Text fz="sm" fw="600">
                  {tab.label}
                </Text>
              </Tabs.Tab>
            ))}
          </Tabs.List>

          <Tabs.Panel value="load" pt="xs">
            <PmdReportLoadTab />
          </Tabs.Panel>

          <Tabs.Panel value="unload" pt="xs">
            <PmdReportUnloadTab />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </div>
  );
};

export default PmdReports;
