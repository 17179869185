import { IconRobot, IconRobotOff, IconTractor } from '@tabler/icons-react';
import GRADIENTS from 'components/Dashboard/colorSchemes';

export const userRoles = {
  'SUPER ADMIN': 'SUPER ADMIN',
  ADMIN: 'ADMIN',
};

export const userWeight = {
  'SUPER ADMIN': 1,
  ADMIN: 2,
  MANAGER: 3,
  'SMD OPERATOR': 4,
  'PMD OPERATOR': 5,
  'FMD OPERATOR': 6,
  'WMV OPERATOR': 7,
};

export const containerTypeConst = {
  PMD_BOX: 'PMD BOX',
  FMD_TROLLEY: 'FMD TROLLEY',
};

export const containerTypeList = [
  {
    label: 'MCB BOX',
    value: 'PMD BOX',
  },
  {
    label: 'FMD TROLLEY',
    value: 'FMD TROLLEY',
  },
];

export const containerStateConst = {
  EMPTY: 'EMPTY',
  FILLED: 'FILLED',
};

export const appUserRoles = [
  {
    label: 'PMD Operator',
    value: 'PMD OPERATOR',
  },
  {
    label: 'FMD Operator',
    value: 'FMD OPERATOR',
  },
  {
    label: 'WMV Operator',
    value: 'WMV OPERATOR',
  },
];

// export const userRoles = {
//   SUPER_ADMIN: { role: 'SUPER ADMIN', weight: 1 },
//   ADMIN: { role: 'ADMIN', weight: 2 },
//   MANAGER: { role: 'MANAGER', weight: 3 },
//   SMD: { role: 'SMD OPERATOR', weight: 4 },
//   PMD: { role: 'PMD OPERATOR', weight: 5 },
//   FMD: { role: 'FMD OPERATOR', weight: 6 },
//   WMV: { role: 'WMV OPERATOR', weight: 7 },
// };

export const userRolesWithWeights = [
  {
    label: 'SUPER ADMIN',
    value: 'SUPER ADMIN',
    weight: 1,
    type: 'ADMIN',
  },
  {
    label: 'ADMIN',
    value: 'ADMIN',
    weight: 2,
    type: 'ADMIN',
  },
  {
    label: 'MANAGER',
    value: 'MANAGER',
    weight: 3,
    type: 'ADMIN',
  },
  {
    label: 'SMD OPERATOR',
    value: 'SMD OPERATOR',
    weight: 4,
    type: 'APP',
  },
  {
    label: 'PMD OPERATOR',
    value: 'PMD OPERATOR',
    weight: 5,
    type: 'APP',
  },
  {
    label: 'FMD OPERATOR',
    value: 'FMD OPERATOR',
    weight: 6,
    type: 'APP',
  },
  // {
  //   label: 'WMV OPERATOR',
  //   value: 'WMV OPERATOR',
  //   weight: 7,
  //   type: 'APP',
  // },
];

export const getSelectableRolesForUser = (userWeight, type) => {
  let roleList;

  if (!userWeight) {
    return [];
  } else {
    if (type) {
      roleList = userRolesWithWeights.filter(
        (role) => role.weight > userWeight && role.type == type,
      );
    } else {
      roleList = userRolesWithWeights.filter(
        (role) => role.weight > userWeight,
      );
    }
  }

  return roleList;
};

export const getUserPresetSelectList = (userWeight) => {
  if (!userWeight) return [];

  if (userWeight < 3) {
    const list = [
      {
        value: 'ADMIN',
        label: 'Admin',
      },
      {
        value: 'APP',
        label: 'App User',
      },
    ];

    return list;
  } else {
    const list = [
      {
        value: 'APP',
        label: 'App User',
      },
    ];

    return list;
  }
};

export const getMachineColor = {
  'IN USE': GRADIENTS.lightgreen,
  IDLE: GRADIENTS.orange,
  DECOMISSIONED: GRADIENTS.red,
};

export const gettMachineIcon = (status) => {
  switch (status) {
    case 'IN USE':
      return <IconRobot color="#ffffff" size="3.8em" />;

    case 'IDLE':
      return <IconRobotOff color="#ffffff" size="3.8em" />;

    case 'DECOMISSIONED':
      return <IconRobotOff color="#ffffff" size="3.8em" />;

    default:
      return (
        <IconRobot color="#ffffff" size="3.8em" className="pop_on_hover" />
      );
  }
};

export const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const timeSlots = [
  '12:00 AM',
  '1:00 AM',
  '2.00 AM',
  '3:00 AM',
  '4:00 AM',
  '5:00 AM',
  '6:00 AM',
  '7:00 AM',
  '8:00 AM',
  '9:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '1:00 PM',
  '2:00 PM',
  '3:00 PM',
  '4.00 PM',
  '5:00 PM',
  '6:00 PM',
  '7:00 PM',
  '8:00 PM',
  '9:00 PM',
  '10:00 PM',
  '11:00 PM',
];

export const getSelectedBoxesFromMatrix = (cells) => {
  const numRows = 24;
  const numCols = 7;
  let selectedBoxes = [];

  Array.from({ length: numRows }, (_, row) =>
    Array.from({ length: numCols }, (_, col) => {
      if (cells[row][col] == true) {
        let myIndex = `${row},${col}`;
        selectedBoxes.push(myIndex);
      }
    }),
  );

  return selectedBoxes;
};

export const checkIfMatrixChanges = (cells) => {
  const numRows = 24;
  const numCols = 7;
  let flag = false;

  Array.from({ length: numRows }, (_, row) =>
    Array.from({ length: numCols }, (_, col) => {
      if (cells[row][col] == true) {
        flag = true;
        return flag;
      }
    }),
  );

  return flag;
};

export const checkIfAllTheCellsAreSelected = (cells) => {
  const numRows = 24;
  const numCols = 7;
  let flag = true;

  Array.from({ length: numRows }, (_, row) =>
    Array.from({ length: numCols }, (_, col) => {
      if (cells[row][col] !== true) {
        flag = false;
        return flag;
      }
    }),
  );

  return flag;
};

export const getRoleFromMachineType = (type) => {
  switch (type) {
    case 'Makers - SMD':
      return 'SMD OPERATOR';

    case 'KDF - FMD':
      return 'FMD OPERATOR';

    default:
      return 'SMD OPERATOR';
  }
};

export const machineType = {
  SMD: 'Makers - SMD',
  FMD: 'KDF - FMD',
};

export const colorList = [
  // '#003f5c',
  // '#665191',
  // '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#00a8cc',
  '#90e39a',
  '#9b5de5',
  '#f15bb5',
  '#fee440',
  '#00b7c2',
  '#b3d4e0',
  '#d6a9e9',
  '#072ac8',
  '#ef9d10',
  '#16c79a',
  '#ffd6ba',
  '#7be0ad',
  '#07beb8',
  '#e8c547',
  '#ef233c',
  '#d4a5a5',
  '#c8c8c8',
  '#ff6f3c',
  '#b68973',
  '#9be564',
  '#1d3461',
  '#f7c59f',
  '#e2725b',
  '#b192b7',
  '#3b0d11',
  '#c0c781',
  '#f0b67f',
  '#0a0908',
  '#fb8b24',
  '#5f0f40',
  '#44c8f5',
  '#b4dfe5',
  '#a63446',
  '#e6af2e',
  '#ffb4a2',
  '#d6cbd3',
  '#58b368',
  '#9d4edd',
  '#7aecec',
  '#614051',
  '#f6cd61',
  '#a9cce3',
  '#ffaaa5',
  '#7d7d7d',
  '#f5a623',
  '#ffa987',
  '#d6ff79',
  '#6bf178',
  '#4f5d75',
  '#a2a79e',
  '#f3b05a',
  '#434b66',
  '#70d6ff',
  '#f79d65',
  '#818a9d',
  '#ca96db',
  '#c6f1e7',
  '#d83f87',
  '#e49b0f',
  '#5a5255',
  '#1a535c',
  '#4ecdc4',
  '#ff6b6b',
  '#ffe66d',
  '#3e92a3',
  '#c9cba3',
  '#706677',
  '#c94c4c',
  '#f6e58d',
  '#ffbe76',
  '#add8e6',
  '#db995a',
  '#98ddca',
  '#ff7f50',
  '#e58e26',
  '#1c1c1c',
  '#a9e5bb',
  '#a3e4d7',
  '#fc427b',
  '#f67280',
  '#f8efba',
  '#c06c84',
  '#6c5b7b',
];

export const addColorToSkuList = (skus) => {
  let newList = skus.map((sku, index) => {
    return {
      ...sku,
      color: colorList[index],
    };
  });
  return newList;
};
export const machineEventActionTypes = {
  UNKNOWN: 'UNKNOWN',
  LOAD: 'LOAD',
  UNLOAD: 'UNLOAD',
};

export const actionStatuses = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  ERROR: 'ERROR',
  UNKNOWN: 'UNKNOWN',
};

export const renamePmdBox = (value) => {
  if (value === 'PMD BOX') {
    return 'MCB BOX';
  } else {
    return value;
  }
};

export const tableActionTypes = {
  UNKNOWN: 'UNKNOWN',
  READ: 'READ',
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};
