export const API_ROUTE_SIGNIN = '/auth/admin/sign-in';
export const API_ROUTE_SIGNOUT = '/auth/logout';

export const FORGOT_PASSWORD = '/forgot-password';

export const API_ROUTE_USER = '/user';

export const API_ROUTE_SKUS = '/skus';
export const API_ROUTE_SKUS_CREATE_BOM = '/skus/bom';

export const API_ROUTE_MATERIALS = '/material';

export const API_ROUTE_CMC = '/cmc';

export const API_ROUTE_MAKERS = '/machines';

export const API_ROUTE_SCEDULE = '/schedule';

export const API_ROUTE_DASHBOARD = '/dashboard';

export const API_ROUTE_BLENDS = '/blends';

export const API_ROUTE_CONTAINER = '/container';

export const API_ROUTE_LOGS = '/logs';

export const API_ROUTE_FILTERS = '/filters';

export const API_ROUTE_APP_BUILD = '/app-build';

export const API_ROUTE_SHIFT = '/shift';

export const API_ROUTE_REPORTS_PMD_LOAD = '/reports/pmd-report-load';
export const API_ROUTE_REPORTS_PMD_UNLOAD = '/reports/pmd-report-unload';
