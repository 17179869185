import {
  ActionIcon,
  Badge,
  Button,
  Checkbox,
  Flex,
  HoverCard,
  Modal,
  Pagination,
  Paper,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import {
  IconArrowLeft,
  IconDownload,
  IconMinus,
  IconPlus,
  IconRefresh,
  IconTextPlus,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { deleteVersions, fetchAllBuilds, markAsLatest } from 'api/appBuild';
import LatestBuildBox from 'components/MobileApplications/LatestBuildBox';
import NoLatestBuildFound from 'components/MobileApplications/NoLatestBuildFound';
import UploadApplication from 'components/MobileApplications/UploadApplication';
import ExportCsvXls from 'components/global/ExportCsvXls';
import FilterBadgeClearable from 'components/global/FilterBadgeClearable';
import InnerPage from 'components/global/InnerPage';
import NotFound from 'components/global/NotFound';
import PageLoader from 'components/global/PageLoader';
import SearchInput from 'components/global/SearchInput';
import ServerErrorBox from 'components/global/ServerErrorBox';
import COLORS from 'constants/colors';
import { userRoles } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NotificationUtil } from 'utils/notifications';
import { handleErrorResponse, isArrayAndHasContent } from 'utils/utils';

const PAGE_TITLE = 'App Distribution';

const MobileApplications = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'App Distribution | Montage';
  }, []);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchKey, setSearchKey] = useState(null);
  const [pageLess, setPageLess] = useState(true);
  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const [downloadLatest, setDownloadLatest] = useState(false);

  const [showUploadModal, setShowUploadModal] = useState(false);

  //checkbox items
  const [selectedContainers, setselectedContainers] = useState([]);

  //functions
  const handleSearch = (value) => {
    setSearchKey(value.trim());
  };

  const setActivePage = (value) => {
    setPage(value);
  };

  const handleRefresh = () => {
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey(null);
  };

  const handleCheckboxChange = (e, item) => {
    if (e.target.checked) {
      setselectedContainers([...selectedContainers, item]);
    } else {
      setselectedContainers(
        selectedContainers.filter(
          (selectedItem) => selectedItem.uid !== item.uid,
        ),
      );
    }
  };

  const ConfirmMarkAsLatestModal = (uid) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you make this version latest?</Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        markAsLatestMutate(uid);
      },
    });
  };

  const { mutate: markAsLatestMutate, isLoading: isMarking } = useMutation({
    mutationFn: async (values) => await markAsLatest(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'Successfully marked as latest',
      });
      refetch();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const ConfirmDeleteVersionsModal = (selectedContainers) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you want to delete these versions?</Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        const getUidList = selectedContainers.map((item) => item.uid) || [];
        const values = {
          appVersions: getUidList,
        };
        deleteVersionsMutate(values);
      },
    });
  };

  const { mutate: deleteVersionsMutate, isLoading: isDeleting } = useMutation({
    mutationFn: async (values) => await deleteVersions(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'Successfully Deleted Versions!',
      });
      refetch();
      setselectedContainers([]);
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const handleDownloadImage = (fileName) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}${fileName}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName.split('/').at(-1);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading image:', error);
      });
  };

  const { data, isLoading, refetch, error, isFetching } = useQuery({
    queryKey: ['fetch-builds', page, pageSize, searchKey],
    queryFn: (values) => fetchAllBuilds(values),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  if (isLoading)
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            {PAGE_TITLE}
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            radius="xs"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <Skeleton height="85vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <InnerPage>
          <ServerErrorBox apiError={true} />
        </InnerPage>
      </div>
    );

  return (
    <div>
      {/* CUSTOM MODAL AND DRAWER START */}
      <Modal
        opened={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        title={<Text fw={600}>Upload Mobile Application</Text>}
        size="md"
        centered={true}>
        <UploadApplication
          onUpdate={() => {
            refetch();
            setShowUploadModal(false);
          }}
        />
      </Modal>
      {/* CUSTOM MODAL AND DRAWER END */}

      <Flex justify="space-between" align="center">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          radius="xs"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        {data?.inRelease ? (
          <LatestBuildBox
            data={data?.inRelease}
            handleDownloadImage={handleDownloadImage}
          />
        ) : (
          <NoLatestBuildFound />
        )}

        {isFetching ? (
          <>
            {' '}
            <Flex justify="space-between" align="center" pt="md">
              <Flex align="center" gap={8}>
                <SearchInput
                  handleRefresh={handleRefresh}
                  handleSearch={handleSearch}
                  placeholder="Search Build Version"
                  invokeRefresh={invokingRefreshForSearchInput}
                  refreshBtn={false}
                />
                <Flex gap={20} align="center" justify="center">
                  <Tooltip label="Refresh">
                    <ActionIcon
                      radius="xs"
                      size="lg"
                      onClick={() => {
                        handleRefresh();
                      }}
                      color="orange"
                      variant="filled">
                      <IconRefresh size={18} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              </Flex>

              <Flex justify="flex-end" align="center" gap={8}>
                {selectedContainers?.length > 0 && (
                  <Button
                    disabled={selectedContainers?.length === 0}
                    sx={{
                      backgroundColor: COLORS.error,
                    }}
                    loading={isDeleting}
                    leftIcon={<IconMinus />}>
                    Delete Versions
                  </Button>
                )}
                <Button
                  sx={{
                    backgroundColor: COLORS.primary,
                  }}
                  onClick={() => {
                    setShowUploadModal(true);
                  }}
                  leftIcon={<IconPlus />}>
                  Add Version
                </Button>
              </Flex>
            </Flex>
            <Flex gap={3} align="center" py="xs">
              <Text fz="xs">
                Showing{' '}
                <span style={{ color: 'red', fontWeight: 600 }}>
                  {data?.appVersions?.length || 0}
                </span>{' '}
                Builds
              </Text>
              {searchKey && (
                <FilterBadgeClearable
                  text={`search: ${searchKey}`}
                  func={() => {
                    setInvokingRefreshForSearchInput(
                      !invokingRefreshForSearchInput,
                    );
                    setSearchKey('');
                  }}
                  color="blue"
                />
              )}
            </Flex>
            <Text fw={600} fz="md" py="xs">
              Other Launched Builds
            </Text>
            <Skeleton height="58vh" />
          </>
        ) : (
          <>
            <Flex justify="space-between" align="center" pt="md">
              <Flex align="center" gap={8}>
                <SearchInput
                  handleRefresh={handleRefresh}
                  handleSearch={handleSearch}
                  placeholder="Search Build Version"
                  invokeRefresh={invokingRefreshForSearchInput}
                  refreshBtn={false}
                />
                <Flex gap={20} align="center" justify="center">
                  <Tooltip label="Refresh">
                    <ActionIcon
                      radius="xs"
                      size="lg"
                      onClick={() => {
                        handleRefresh();
                      }}
                      color="orange"
                      variant="filled">
                      <IconRefresh size={18} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              </Flex>

              <Flex justify="flex-end" align="center" gap={8}>
                {selectedContainers?.length > 0 && (
                  <Button
                    disabled={selectedContainers?.length === 0}
                    sx={{
                      backgroundColor: COLORS.error,
                    }}
                    //loading={isDeleting}
                    //radius="xs"
                    onClick={() => {
                      ConfirmDeleteVersionsModal(selectedContainers);
                    }}
                    leftIcon={<IconMinus />}>
                    Delete Versions
                  </Button>
                )}
                <Button
                  sx={{
                    backgroundColor: COLORS.primary,
                  }}
                  onClick={() => {
                    setShowUploadModal(true);
                  }}
                  leftIcon={<IconPlus />}>
                  Add Version
                </Button>
              </Flex>
            </Flex>
            <Flex gap={3} align="center" py="xs">
              <Text fz="xs">
                Showing{' '}
                <span style={{ color: 'red', fontWeight: 600 }}>
                  {data?.appVersions?.length || 0}
                </span>{' '}
                Builds
              </Text>
              {searchKey && (
                <FilterBadgeClearable
                  text={`search: ${searchKey}`}
                  func={() => {
                    setInvokingRefreshForSearchInput(
                      !invokingRefreshForSearchInput,
                    );
                    setSearchKey('');
                  }}
                  color="blue"
                />
              )}
            </Flex>
            <Text fw={600} fz="md" py="xs">
              Other Launched Builds
            </Text>
            <Stack
              sx={{
                minHeight: '58vh',
              }}>
              {isArrayAndHasContent(data?.appVersions) ? (
                <ScrollArea h="58vh">
                  <Table highlightOnHover>
                    <thead
                      style={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                      }}>
                      <tr>
                        <th>
                          <Checkbox
                            checked={
                              selectedContainers?.length ===
                              data?.appVersions?.length
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                setselectedContainers(data?.appVersions);
                              } else {
                                setselectedContainers([]);
                              }
                            }}
                          />
                        </th>
                        <th>Version</th>
                        <th>Build Number</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.appVersions.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <Checkbox
                              checked={selectedContainers?.some(
                                (selectedItem) => selectedItem.uid === item.uid,
                              )}
                              onChange={(e) => {
                                handleCheckboxChange(e, item);
                              }}
                            />
                          </td>
                          <td>{item?.buildName}</td>
                          <td>{item?.buildNumber}</td>

                          <td>
                            <Flex gap={10}>
                              <Tooltip label={'Download'}>
                                <ActionIcon
                                  disabled={!item?.appUrl}
                                  variant="light"
                                  color="teal"
                                  onClick={() =>
                                    handleDownloadImage(item?.appUrl)
                                  }>
                                  <IconDownload size={16} />
                                </ActionIcon>
                              </Tooltip>
                              <Tooltip label="Mark Latest">
                                <ActionIcon
                                  variant="light"
                                  color="cyan"
                                  disabled={!item?.appUrl}
                                  onClick={() => {
                                    ConfirmMarkAsLatestModal(item.uid);
                                  }}>
                                  <IconTextPlus size={16} />
                                </ActionIcon>
                              </Tooltip>
                            </Flex>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </ScrollArea>
              ) : (
                <NotFound title="No App version found!" />
              )}
              <Flex justify="space-between">
                <Stack justify="end"></Stack>
                <Pagination
                  mt="20px"
                  page={page}
                  onChange={setActivePage}
                  total={Math.ceil(data?.totalEntries / pageSize)}
                />
              </Flex>
            </Stack>
          </>
        )}
      </Paper>
    </div>
  );
};

export default MobileApplications;
