import { Button, Card, Flex, Text } from '@mantine/core';
import { IconDeviceMobileCog, IconDeviceMobileDown } from '@tabler/icons-react';
import COLORS from 'constants/colors';
import dayjs from 'dayjs';
import React from 'react';

const NoLatestBuildFound = () => {
  return (
    <Card sx={{ backgroundColor: COLORS.primary, color: 'white' }}>
      <Flex justify="space-between" align="center" wrap="wrap">
        <Flex gap={20} align="center">
          <IconDeviceMobileDown size="3em" />

          <Flex direction="column">
            <Text fw={700} fz="lg">
              No Deployed Build Found
            </Text>
            <Text fz="xs">Upload Latest build Now!</Text>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default NoLatestBuildFound;
