const { createStyles } = require('@mantine/core');

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: 'flex',
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.black,
    fontSize: theme.fontSizes.sm,
    marginTop: 15,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.black,
      transform: 'scale(1.1)',
    },
  },

  link: {
    fontWeight: 500,
    display: 'block',
    textDecoration: 'none',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    paddingLeft: 31,
    marginLeft: 30,
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[7],
    borderLeft: `1px solid ${theme.colors.gray[3]}`,

    // '&:hover': {
    //   backgroundColor: theme.colors.gray[0],
    //   color: theme.black,
    // },
  },

  chevron: {
    transition: 'transform 200ms ease',
  },
}));

export default useStyles;
