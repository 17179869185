import {
  Box,
  Button,
  Flex,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Stepper,
  Text,
  createStyles,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { useMutation } from '@tanstack/react-query';
import { checkCalendarUpload, uploadCalendarFile } from 'api/shift';
import COLORS from 'constants/colors';
import { useState } from 'react';
import { NotificationUtil } from 'utils/notifications';
import UploadingForPreviewGif from '../../assets/upload_loading.gif';
import SuccessGif from '../../assets/success.gif';
import UploadingFileGif from '../../assets/uploading_web.gif';
import DisclaimerPanel from './DisclaimerPanel';
import ShiftCalenderTable from './ShiftCalenderTable';
import Uploader from './Uploader';

const useStyles = createStyles(() => ({
  stepperBox: {
    backgroundColor: COLORS.white,
    borderColor: 'darkgray',
    borderStyle: 'dotted',
    borderRadius: 'none',
    minHeight: '61vh',
    width: '75vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
}));

const UploadCalendar = ({ onUpdate }) => {
  const { classes } = useStyles();

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [tempFilePath, setTempFilePath] = useState(null);

  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const [calendarUploadResp, setCalendarUploadResp] = useState(null);

  const { mutate: uploadMutate, isLoading: isUploading } = useMutation({
    mutationFn: async (values) => await checkCalendarUpload(values),
    onSuccess: (response) => {
      setCalendarUploadResp(response?.data);
      setTempFilePath(response?.data?.tempFilePath);
      nextStep();
      // NotificationUtil({
      //   success: true,
      //   title: 'Calendar Data Fetched',
      //   message: 'Calendar Data Fetched Successfully!',
      // });
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error?.response?.data?.message,
      });
    },
  });

  const { mutate: finalUploadMutate, isLoading: isFileUploading } = useMutation(
    {
      mutationFn: async (values) => await uploadCalendarFile(values),
      onSuccess: (response) => {
        if (response?.status === 200) {
          setSelectedFile(null);
          setFileName('');
          setCalendarUploadResp(null);
          setTempFilePath(null);
          nextStep();
          // NotificationUtil({
          //   success: true,
          //   title: 'Calendar Uploaded Successfully',
          //   message: 'Calendar Uploaded Successfully!',
          // });

          setTimeout(() => {
            onUpdate();
          }, 2000);
        }
      },
      onError: (error) => {
        NotificationUtil({
          success: false,
          title: 'Error',
          message: error.response.data.message,
        });
      },
    },
  );

  const uploadFile = async (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('shiftCalender', selectedFile);
      ConfirmModal(formData);
    }
  };

  const uploadFileFinal = async () => {
    if (tempFilePath) {
      let reqBody = {
        tempFilePath: tempFilePath,
      };
      ConfirmFileUploadModal(reqBody);
    }
  };

  const ConfirmModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you want to upload this file?</Text>
      ),
      confirmProps: { color: 'blue' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        uploadMutate(values);
      },
    });
  };

  const ConfirmFileUploadModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you want to upload this file?</Text>
      ),
      confirmProps: { color: 'blue' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        finalUploadMutate(values);
      },
    });
  };

  return (
    <Stack
      justify="center"
      align="center"
      sx={{
        height: '80vh',
        width: '80vw',
      }}>
      <Stepper active={active} onStepClick={null} breakpoint="sm" py="xl">
        {/* uploader */}
        <Stepper.Step
          label="Upload Calendar File"
          description="Upload Calendar File (.xlsx)">
          <ScrollArea h="65vh">
            <div className={classes.stepperBox}>
              {!isUploading ? (
                <Uploader
                  uploadFile={uploadFile}
                  isUploading={isUploading}
                  title=" Upload Updated Calendar"
                  desc="Please Upload Valid Calendar Format (xlsx/excel)"
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  fileName={fileName}
                  setFileName={setFileName}
                />
              ) : (
                <Flex
                  direction="column"
                  gap={10}
                  justify="center"
                  align="center">
                  <Text fw={700} fz="xl">
                    Uploading Calendar For Preview
                  </Text>
                  <Box py="md">
                    <img src={UploadingForPreviewGif} alt="uploading.." />
                  </Box>
                  <Text fz="sm" c="dimmed">
                    Please wait a moment ...
                  </Text>
                </Flex>
              )}
            </div>
          </ScrollArea>
        </Stepper.Step>

        {/* preview */}
        <Stepper.Step
          label="Preview Uploaded Calendar"
          description="Check Calendar Preview">
          <ScrollArea
            h="63vh"
            style={{
              height: 'auto !important',
              width: '75vw',
              padding: '1em',
              borderStyle: 'dotted',
            }}>
            {calendarUploadResp ? (
              <div>
                <Flex direction="column" gap={50} mb="3em">
                  {calendarUploadResp?.data?.map((monthTable, index) => (
                    <ShiftCalenderTable
                      data={monthTable}
                      key={index}
                      handleCellSelect={() => {}}
                    />
                  ))}
                </Flex>
              </div>
            ) : (
              <div>
                <LoadingOverlay visible={true} />
              </div>
            )}
          </ScrollArea>
        </Stepper.Step>

        {/* final upload */}
        <Stepper.Step
          label="Confirm Calendar Upload"
          description="Confirm and finish the upload">
          <ScrollArea h="61vh">
            <div className={classes.stepperBox}>
              {isFileUploading ? (
                <div>
                  <Flex
                    direction="column"
                    gap={10}
                    justify="center"
                    align="center">
                    <Text fz="sm" c="dimmed">
                      Please don&apos;t close or reload while uploading
                    </Text>
                    <div>
                      <img
                        src={UploadingFileGif}
                        alt="uploading.."
                        height="200px"
                      />
                    </div>
                    <Text fz="sm" c="dimmed">
                      Please wait a moment ...
                    </Text>
                    <Text fw={700} fz="1.6rem">
                      Updating Calendar
                    </Text>
                  </Flex>
                </div>
              ) : (
                <DisclaimerPanel
                  uploadFile={uploadFileFinal}
                  isUploading={isFileUploading}
                  selectedFile={selectedFile}
                  fileName={fileName}
                  onUpdate={onUpdate}
                />
              )}
            </div>
          </ScrollArea>
        </Stepper.Step>

        <Stepper.Step
          label="Successfully Updated"
          description="Calendar update completed">
          <ScrollArea h="65vh">
            <div className={classes.stepperBox}>
              <div>
                <Flex
                  direction="column"
                  gap={5}
                  justify="center"
                  align="center">
                  <div>
                    <img src={SuccessGif} alt="Uploaded" height="200px" />
                  </div>

                  <Text fw={700} fz="md">
                    Calendar Updated Successfully
                  </Text>
                  <Button
                    mt="md"
                    size="lg"
                    onClick={() => onUpdate()}
                    sx={{ backgroundColor: COLORS.primary }}>
                    Continue
                  </Button>
                </Flex>
              </div>
            </div>
          </ScrollArea>
        </Stepper.Step>
      </Stepper>

      <Flex
        justify="space-between"
        align="center"
        style={{ width: '100%' }}
        py="sm">
        {active == 1 ? (
          <div>
            <Text fz="md" color="red" fw={600}>
              *** Please review the whole calendar and continue
            </Text>
          </div>
        ) : (
          <div></div>
        )}
        <Flex justify="center" align="center" gap={5}>
          {active >= 1 && active < 3 ? (
            <Button variant="default" onClick={prevStep} size="md">
              Back
            </Button>
          ) : (
            <></>
          )}
          {active == 1 ? (
            <Button onClick={nextStep} size="md">
              Continue Upload
            </Button>
          ) : (
            <></>
          )}
        </Flex>
      </Flex>
    </Stack>
  );
};

export default UploadCalendar;
