import axios from 'api/axiosInstance';
import { API_ROUTE_SHIFT } from 'constants/apiRoutes';

export const fetchCalenderData = async (context) => {
  const response = await axios.get(`${API_ROUTE_SHIFT}/calendar`);
  return response;
};

export const checkCalendarUpload = async (formData) => {
  const response = await axios.post(`${API_ROUTE_SHIFT}/preview`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response;
};

export const uploadCalendarFile = async (values) => {
  const response = await axios.patch(`${API_ROUTE_SHIFT}/update`, values);

  return response;
};

export const editCalendarCell = async (values) => {
  const response = await axios.patch(`${API_ROUTE_SHIFT}/modify`, values);
  return response;
};
