import { useState, useEffect, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'api/axiosInstance';
import { API_ROUTE_SIGNOUT } from 'constants/apiRoutes';
import { IconCheck, IconX } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';

export const AuthContext = createContext({
  appLoaded: false,
  isLoggedIn: false,
  user: null,
  tempData: null,
  saveTempData: () => {},
  clearTempData: () => {},
  login: () => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [appLoaded, setAppLoaded] = useState(false);
  const [tempData, setTempData] = useState(null);

  useEffect(() => {
    const tokenUpdateHandler = () => {
      const cachedUser = JSON.parse(localStorage.getItem('admin'));

      setUser((preUser) => {
        return { ...preUser, token: cachedUser.token };
      });
    };

    document.addEventListener('tokenUpdated', tokenUpdateHandler, false);

    return () => {
      document.removeEventListener('tokenUpdated', tokenUpdateHandler);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('logout', logout, false);

    return () => {
      document.removeEventListener('logout', logout);
    };
  }, []);

  useEffect(() => {
    async function authenticate() {
      try {
        const user = JSON.parse(localStorage.getItem('admin'));

        if (user && user.token) {
          // const { data } = await axios.post('/admin/auth/authenticate', null, {
          //   headers: { Authorization: `Bearer ${user.token}` },
          // });

          setAppLoaded(true);
          return login({ ...user, token: user.token });
        }

        clearAuthState();
        setAppLoaded(true);
      } catch (err) {
        setAppLoaded(true);

        if (err.response) {
          const { status } = err.response;

          if ([404, 401, 500].includes(status)) {
            clearAuthState();
            return navigate('/');
          }
        }
      }
    }

    authenticate();
  }, []);

  const clearAuthState = () => {
    localStorage.removeItem('admin');
    setUser(null);
    setIsLoggedIn(false);
  };

  const login = (user, redirect) => {
    localStorage.setItem('admin', JSON.stringify(user));
    setUser(user);
    setIsLoggedIn(true);

    if (redirect) {
      navigate(redirect, { replace: true });
    }
  };

  const logout = async (url = '/') => {
    await axios
      .post(API_ROUTE_SIGNOUT)
      .then((_) =>
        showNotification({
          title: 'Logged Out',
          message: 'You have been logged out successfully',
          color: 'grean',
          icon: <IconCheck />,
        }),
      )
      .catch(
        (err) => console.log(err),
        // showNotification({
        //   title: parseResponseError(error),
        //   message: 'something went wrong, try again.',
        //   color: 'red',
        //   icon: <IconX />,
        // }),
      );

    clearAuthState();

    navigate(url, { replace: true });
  };

  const saveTempData = (data) => {
    setTempData(data);
  };

  const clearTempData = (data) => {
    setTempData(null);
  };

  return (
    <AuthContext.Provider
      value={{
        appLoaded,
        isLoggedIn,
        user,
        tempData,
        saveTempData,
        clearTempData,
        login,
        logout,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};
