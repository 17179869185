import {
  Button,
  Flex,
  LoadingOverlay,
  Pagination,
  ScrollArea,
  Skeleton,
  Space,
  Stack,
  Table,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { IconBoxOff } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { fetchAllContainerLogs, fetchAllEventLogs } from 'api/logs';
import { exportPmdReportLoad, removeExportFile } from 'api/reports';
import NotFound from 'components/global/NotFound';
import ServerErrorBox from 'components/global/ServerErrorBox';
import COLORS from 'constants/colors';
import { AuthContext } from 'context/auth-context';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { NotificationUtil } from 'utils/notifications';
import { handleErrorResponse, isArrayAndHasContent } from 'utils/utils';

const PAGE_SIZE = 20;

const PmdReportLoadTab = () => {
  const [activePage, setActivePage] = useState(1);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const { user } = useContext(AuthContext);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: ['container-logs-load', activePage, startDate, endDate],
    queryFn: () =>
      fetchAllContainerLogs({
        page: 1,
        searchKey: null,
        pageSize: 20,
        actionType: 'load',
        status: null,
        startDate,
        endDate,
        containerType: null,
        type: 'pmd_box',
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    // refetchInterval: 30000,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  // const exportData = () => {
  //   const fileName = `PMD-REPORT-LOAD-${dayjs(new Date(startDate)).format('DD-MM-YYYY')}${endDate ? `-to-${dayjs(new Date(endDate)).format('DD-MM-YYYY')}` : ''}`;
  //   const exportData = data.eventLogs.map((log) => {
  //     return {
  //       'Event ID': log?.uid || '',
  //       Date: dayjs(log?.createdAt).format('DD MMM YYYY') || '',
  //       Time: dayjs(log?.createdAt).format('hh:mm A') || '',
  //       Shift: log?.containerLog?.shiftInfo || '',
  //       'Container Type': log?.containerInfo?.type || '',
  //       'Container Code': log?.containerInfo?.uid || '',
  //       'Operator Name': log?.operatorInfo?.name || '',
  //       Message: log?.message || '',
  //       'Event Type': log?.containerLog?.reference?.split('*@*')[0] || '',
  //       Module: log?.machineInfo?.name || '',
  //       'SMD Maker running SKU':
  //         log?.containerLog?.reference?.split('*@*').at(-2) || '',
  //       Blend: log?.containerLog?.blendInfo?.name || '',
  //       'Quantity (kg)': log?.containerLog?.blendQuantity || '',
  //       Tipper: log?.containerLog?.tipper || '',
  //       'Operation No': log?.containerLog?.operationNo || '',
  //       'Current Box Status': log?.containerInfo?.state || '',
  //     };
  //   });
  //   exportFromJSON({ data: exportData, fileName, exportType: 'xls' });
  // };

  const { mutate: exportMutate, isLoading: isExporting } = useMutation({
    mutationFn: async () =>
      await exportPmdReportLoad({
        page: 1,
        searchKey: null,
        pageSize: 20,
        actionType: 'load',
        status: null,
        startDate,
        endDate,
        containerType: null,
        type: 'pmd_box',
        pageLess: true,
      }),
    onSuccess: (data) => {
      handleDownloadImage({
        fileUrl: data.data.filePath,
        fileName: data.data.fileName,
      });
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const handleDownloadImage = async (file) => {
    try {
      const response = await fetch(file.fileUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${user.token}`,
          'x-refresh-token': user.refreshToken,
        },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = file.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      notifications.clean();
      NotificationUtil({
        success: true,
        title: 'File Downloaded',
        message: 'Your file has been downloaded successfully.',
      });
      removeExportFileMutate(file.fileName);
    } catch (error) {
      console.error('Error downloading the ZIP file:', error);
    }
  };

  const { mutate: removeExportFileMutate, isLoading: removeExportFileLoading } =
    useMutation({
      mutationFn: (fileName) => removeExportFile(fileName),
      keepPreviousData: false,
    });

  if (isLoading)
    return (
      <div>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const { containerLogs, total } = data;
  return (
    <div>
      <Flex justify="space-between">
        <Flex align="center" gap={5}>
          <DateInput
            placeholder="Start Date"
            value={startDate}
            onChange={(value) => {
              if (!value) {
                setStartDate(new Date());
              } else {
                setStartDate(value);
              }
            }}
            sx={{
              maxWidth: '200px',
            }}
            clearable
            maxDate={new Date()}
            weekendDays={[5, 6]}
          />
          <DateInput
            placeholder="End Date"
            // value={endDate}
            onChange={(value) => {
              if (dayjs(value).format('HH:mm') === '00:00') {
                setEndDate(dayjs(value).endOf('day'));
              } else {
                setEndDate(value);
              }
            }}
            sx={{
              maxWidth: '200px',
            }}
            clearable
            minDate={startDate ? startDate : new Date()}
            weekendDays={[5, 6]}
          />
        </Flex>
        <Stack align="end">
          <Button
            onClick={() => {
              exportMutate();
              NotificationUtil({
                success: true,
                title: 'Processing',
                message:
                  'Your request is being processed. Feel free to browse the app while waiting for the download to finish.',
                autoClose: false,
              });
            }}
            loading={isExporting}
            sx={{
              backgroundColor: COLORS.primary,
            }}>
            Download XLS
          </Button>
        </Stack>
      </Flex>
      <Space h="20px" />
      <div>
        <ScrollArea h="65vh">
          <LoadingOverlay visible={isLoading || isFetching} zIndex={1} />
          <Table highlightOnHover withColumnBorders={false} fontSize="xs">
            <thead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: COLORS.primary,
              }}>
              <tr>
                <th
                  style={{
                    color: 'white',
                  }}>
                  Event ID
                </th>
                <th
                  style={{
                    color: 'white',
                  }}>
                  Date
                </th>
                <th
                  style={{
                    color: 'white',
                  }}>
                  Time
                </th>
                <th
                  style={{
                    color: 'white',
                  }}>
                  Shift
                </th>
                <th
                  style={{
                    color: 'white',
                  }}>
                  Container Type
                </th>
                <th
                  style={{
                    color: 'white',
                    //   minWidth: '150px',
                  }}>
                  Container Code
                </th>
                <th
                  style={{
                    color: 'white',
                    //   backgroundColor: '#00b4d8',
                    //   minWidth: '100px',
                  }}>
                  Operator Name
                </th>
                <th
                  style={{
                    color: 'white',
                    //   backgroundColor: '#00b4d8',
                    //   minWidth: '100px',
                  }}>
                  Message
                </th>
                <th
                  style={{
                    color: 'white',
                    //   backgroundColor: '#6a994e',
                    //   minWidth: '150px',
                  }}>
                  Event Type
                </th>
                {/* <th
                  style={{
                    color: 'white',
                    //   backgroundColor: '#6a994e',
                    //   minWidth: '100px',
                  }}>
                  Module
                </th> */}
                {/* <th
                  style={{
                    color: 'white',
                    //   backgroundColor: '#6a994e',
                    maxWidth: '100px',
                  }}>
                  SMD Maker running SKU
                </th> */}
                <th
                  style={{
                    color: 'white',
                    //   backgroundColor: '#6a994e',
                    //   minWidth: '100px',
                  }}>
                  Blend
                </th>
                <th
                  style={{
                    color: 'white',
                    //   backgroundColor: '#6a994e',
                    //   minWidth: '100px',
                  }}>
                  Quantity (kg)
                </th>
                {/* <th
                  style={{
                    color: 'white',
                    //   backgroundColor: '#6a994e',
                    //   minWidth: '100px',
                  }}>
                  Tipper
                </th> */}
                <th
                  style={{
                    color: 'white',
                    //   backgroundColor: '#6a994e',
                    //   minWidth: '100px',
                  }}>
                  Operation No
                </th>
                <th
                  style={{
                    color: 'white',
                    //   backgroundColor: '#6a994e',
                    maxWidth: '100px',
                  }}>
                  Current Box Status
                </th>
              </tr>
            </thead>
            <tbody>
              {containerLogs?.map((log, index) => (
                <tr key={index}>
                  <td>{log.uid}</td>
                  <td>{dayjs(log.createdAt).format('DD MMM YYYY')}</td>
                  <td> {dayjs(log.createdAt).format('hh:mm A')}</td>
                  <td>{log?.shiftInfo}</td>
                  <td>{log?.containerInfo?.type}</td>
                  <td>{log?.containerInfo?.uid}</td>
                  <td>{log?.operatorInfo?.name}</td>
                  <td>{log?.message}</td>
                  <td>{log?.reference?.split('*@*')[0]}</td>
                  {/* <td>{log?.machineInfo?.name}</td> */}
                  {/* <td>{log?.reference?.split('*@*').at(-2)}</td> */}
                  <td>{log?.blendInfo?.name}</td>
                  <td>{log?.blendQuantity}</td>
                  {/* <td>{log?.tipper}</td> */}
                  <td>{log?.operationNo}</td>
                  <td>{log?.containerInfo?.state}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {!isArrayAndHasContent(containerLogs) && (
            <Stack
              justify="center"
              align="center"
              sx={{
                height: '51vh',
              }}>
              <NotFound
                title="No report data found"
                subtext={''}
                icon={<IconBoxOff size={70} color="#4562f7" />}
              />
            </Stack>
          )}
        </ScrollArea>
      </div>
      <Flex justify="end">
        <Pagination
          mt="20px"
          value={activePage}
          onChange={setActivePage}
          total={Math.ceil(data?.total / 10)}
        />
      </Flex>
    </div>
  );
};

export default PmdReportLoadTab;
