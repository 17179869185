import axios from 'api/axiosInstance';
import { API_ROUTE_APP_BUILD } from 'constants/apiRoutes';

export const fetchAllBuilds = async (context) => {
  const response = await axios.get(API_ROUTE_APP_BUILD, {
    params: {
      page: context.queryKey[1],
      pageSize: context.queryKey[2],
      searchKey: context.queryKey[3],
      pageless: context.queryKey[4],
      release: context.queryKey[5],
    },
  });

  if (context.queryKey[5]) {
    return {
      inRelease: response?.data?.data,
    };
  }

  return {
    inRelease: response?.data?.data?.inRelease || null,
    totalEntries: response?.data?.data?.totalEntries
      ? parseInt(response?.data?.data?.totalEntries)
      : 0,
    appVersions: response?.data?.data?.appVersions,
  };
};

export const uploadApk = async (values) => {
  const response = await axios.post(API_ROUTE_APP_BUILD, values, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const markAsLatest = async (uid) => {
  const response = await axios.patch(`${API_ROUTE_APP_BUILD}/${uid}`);
  return response;
};

export const deleteVersions = async (values) => {
  const response = await axios.post(`${API_ROUTE_APP_BUILD}/delete`, values);
  return response;
};
