import { Button, Card, Flex, Text } from '@mantine/core';
import { IconDeviceMobileCog, IconDeviceMobileDown } from '@tabler/icons-react';
import COLORS from 'constants/colors';
import dayjs from 'dayjs';
import React from 'react';

const LatestBuildBox = ({ data, handleDownloadImage }) => {
  return (
    <Card sx={{ backgroundColor: COLORS.primary, color: 'white' }}>
      <Flex justify="space-between" align="center" wrap="wrap">
        <Flex gap={20} align="center">
          <IconDeviceMobileCog size="3em" />

          <Flex direction="column">
            <Text fw={700} fz="lg">
              Latest Release Launched
            </Text>
            <Text fz="xs">
              at {dayjs(data?.updatedAt).format('DD MMM YYYY - hh:mm A')}
            </Text>
          </Flex>
        </Flex>
        <Text fw={600} fz="xl">
          Version : {data?.buildName}
        </Text>

        <Text fw={600} fz="xl">
          Build No: {data?.buildNumber}
        </Text>

        <Flex justify="flex-end">
          <Button
            rightIcon={<IconDeviceMobileDown />}
            onClick={() => handleDownloadImage(data?.appUrl)}>
            Download Latest Build
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

export default LatestBuildBox;
