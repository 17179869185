import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import axios from 'axios';
import { parseResponseError } from 'utils/utils';
// create axios instance

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  timeout: 60 * 1000,
});

//request interceptor to add the auth token header to requests
instance.interceptors.request.use(
  (config) => {
    // checking if the access token exist in the localStorage
    const admin = JSON.parse(localStorage.getItem('admin'));

    config.headers = {
      'Content-Type': config.headers['Content-Type']
        ? config.headers['Content-Type']
        : 'application/json',
      Authorization: `Bearer ${admin?.token}`,
      'x-mt-refresh': admin?.refreshToken,
    };
    return config;
  },
  (error) => {
    // if (error.response) {
    //   console.error(error.response);
    // } else if (error.request) {
    //   errorNoitif('Internal server error');
    // } else {
    //   errorNoitif(error?.response?.data?.message);
    // }
    // showNotification({
    //   message: parseResponseError(error),
    //   title: 'Oops! something went wrong, try again..',
    //   color: 'red',
    //   icon: <IconX />,
    // });
    return Promise.reject(error);
  },
);

// response interceptor to refresh token on receiving token expired error
instance.interceptors.response.use(
  (response) => {
    const newAccessToken = response.headers['x-access-token'];

    const admin = JSON.parse(localStorage.getItem('admin'));

    if (newAccessToken && newAccessToken !== admin?.token) {
      // console.log('new access token', newAccessToken);
      localStorage.setItem(
        'admin',
        JSON.stringify({ ...admin, token: newAccessToken }),
      );
      window.document.dispatchEvent(new Event('tokenUpdated'));
    }

    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      if (error.response.data?.isPasswordResetRequired) {
        return Promise.reject(error);
      }
      // logging user out
      showNotification({
        message: parseResponseError(error),
        title: 'Oops! something went wrong, try again..',
        color: 'red',
        icon: <IconX />,
      });
      window.document.dispatchEvent(new Event('logout'));
      return;
    }
    // if (error.response.status === 500) {
    //   errorNoitif(error?.response?.data?.message);
    // }
    // if (error.response.status === 404) {
    //   errorNoitif(error?.response?.data?.message);
    // }
    // if (error.response.status === 400) {
    //   errorNoitif(error?.response?.data?.message);
    // }
    // if (error.response.status === 403) {
    //   errorNoitif(error?.response?.data?.message);
    // }
    // if (error.response) {
    //   console.error(error.response);
    // } else if (error.request) {
    //   errorNoitif('Internal server error');
    // } else {
    //   errorNoitif('Internal server error');
    // }
    // showNotification({
    //   title: parseResponseError(error),
    //   message: 'something went wrong, try again.',
    //   color: 'red',
    //   icon: <IconX />,
    // });

    return Promise.reject(error);
  },
);

export default instance;
