import {
  Box,
  Group,
  Text,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { isArrayAndHasContent } from 'utils/utils';

import useStyles from './style';

const NavBarItem = (props) => {
  const location = useLocation();

  const { icon: Icon, label, links, link, disabled } = props;

  const { classes } = useStyles();

  const [opened, setOpened] = useState(false);

  let items = [];
  let hasLinks = false;

  if (isArrayAndHasContent(links)) {
    hasLinks = true;

    items = links.map((link) => (
      <Text
        component={Link}
        className={classes.link}
        to={link.link}
        key={link.label}>
        {link.label}
      </Text>
    ));
  }

  return (
    <Tooltip label={label} position="right">
      <UnstyledButton
        {...(link && { component: Link, to: disabled ? '#' : link })}
        onClick={() => setOpened(!opened)}
        className={classes.control}>
        <Group position="apart" spacing={0}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <ThemeIcon
              variant={location.pathname.includes(link) ? 'filled' : 'light'}
              size={45}
              color="orange">
              <Icon size="1.5rem" stroke={1.5} />
            </ThemeIcon>
            {/* <Box ml="md">{label}</Box> */}
          </Box>

          {hasLinks && (
            <IconChevronRight
              className={classes.chevron}
              size={14}
              stroke={1.5}
              style={{
                transform: opened ? 'rotate(90deg)' : 'none',
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {/* 
      <Collapse in={opened}>{items}</Collapse> */}
    </Tooltip>
  );
};

export default NavBarItem;
