import { useIdle } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { AuthContext } from 'context/auth-context';
import { useContext, useEffect } from 'react';

const IdleWrapper = ({ children }) => {
  const { logout } = useContext(AuthContext);

  const queryClient = useQueryClient();

  const idle = useIdle(60 * 60 * 1000, { initialState: false });

  const logoutUser = () => {
    window.document.dispatchEvent(new Event('logout'));

    alert('You have been logged out due to inactivity');
  };

  useEffect(() => {
    if (idle === true) {
      if (window.location.pathname !== '/') {
        logoutUser();
        queryClient.invalidateQueries();
      }
    }
  }, [idle]);

  return <div>{children}</div>;
};

export default IdleWrapper;
