import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';

export const isArrayAndHasContent = (arr) => {
  return Array.isArray(arr) && arr.length > 0;
};

export const isObjectAndHasProperties = (obj) => {
  return obj !== null && typeof obj === 'object' && Object.keys(obj).length > 0;
};

export const percentageColorMapper = (percentage) => {
  if (percentage <= 20 && percentage >= 0) {
    return 'red';
  } else if (percentage <= 50 && percentage > 20) {
    return 'orange';
  } else if (percentage <= 80 && percentage > 50) {
    return 'yellow';
  } else {
    return 'green';
  }
};

export const crossMaterialCheckStatusColorMapper = (status) => {
  if (status === 'IN PROGRESS') {
    return 'orange';
  } else if (status === 'COMPLETE') {
    return 'green';
  } else {
    return 'red';
  }
};

export const parseResponseError = (err) => {
  if (err.response) {
    const { data } = err.response;

    if (data.validation && data.validation.body) {
      return data.validation.body.message;
    } else if (data.message) {
      return data.message;
    } else {
      return data.message;
    }
  }

  return 'Please try again later.';
};

export const getInitialFromName = (name) => {
  if (typeof name !== 'string' || name.length === 0) {
    return null;
  }

  const split = name.split(' ');
  let initials = '';

  for (let i = 0; i < split.length; i++) {
    if (initials.length === 3) {
      break;
    }

    initials += split[i][0];
  }

  return initials.toUpperCase();
};

export const HumanizeDuration = (
  durationData,
  format = [],
  shortHand = true,
) => {
  //duration : number in milliseconds
  //format: array that includes the timestamps required.
  //format example: ['hour', 'minute', 'second]
  //shortHand is for hrs or hours

  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  let inMilliseconds = null;
  let inSeconds = null;
  let inMinutes = null;
  let inHours = null;

  if (format.length > 0) {
    if (
      format.includes('hour') ||
      format.includes('hours') ||
      format.includes('HOUR') ||
      format.includes('HOURS')
    ) {
      inHours = dayjs.duration(durationData).hours();
    }
    if (
      format.includes('minute') ||
      format.includes('minutes') ||
      format.includes('MINUTE') ||
      format.includes('MINUTES')
    ) {
      inMinutes = dayjs.duration(durationData).minutes();
    }
    if (
      format.includes('second') ||
      format.includes('seconds') ||
      format.includes('SECOND') ||
      format.includes('SECONDS')
    ) {
      inSeconds = dayjs.duration(durationData).seconds();
    }
    if (
      format.includes('millisecond') ||
      format.includes('milliseconds') ||
      format.includes('MILLISECOND') ||
      format.includes('MILLISECONDS')
    ) {
      inMilliseconds = dayjs.duration(durationData).milliseconds();
    }
  } else {
    inMilliseconds = dayjs.duration(durationData).milliseconds();
    inSeconds = dayjs.duration(durationData).seconds();
    inMinutes = dayjs.duration(durationData).minutes();
    inHours = dayjs.duration(durationData).hours();
  }

  if (shortHand) {
    return `${inHours ? inHours + ' hr' : ''} ${
      inMinutes ? inMinutes + ' min' : ''
    } ${inSeconds ? inSeconds + ' sec' : ''} ${
      inMilliseconds ? inMilliseconds + ' ms' : ''
    }`;
  } else {
    return `${inHours ? inHours + ' hours' : ''} ${
      inMinutes ? inMinutes + ' minutes' : ''
    } ${inSeconds ? inSeconds + ' seconds' : ''} ${
      inMilliseconds ? inMilliseconds + ' milliseconds' : ''
    }`;
  }
};

export const handleErrorResponse = (error) => {
  showNotification({
    title: parseResponseError(error),
    message: 'Something went wrong, try again.',
    color: 'red',
    icon: <IconX />,
  });
};

export const encryptPasswordTemporarily = (value) => {
  const password = value.toString();
  const firstHalf = password.slice(0, password.length / 2);
  const secondHalf = password.slice(password.length / 2, password.length);

  const encryptedFirstHalf = firstHalf.split('').reverse().join('');
  const encryptedSecondHalf = secondHalf.split('').reverse().join('');

  const encryptedPassword =
    encryptedFirstHalf.toString() + encryptedSecondHalf.toString();

  return encryptedPassword;
};

export const decryptPasswordTemporarily = (value) => {
  const encryptedPassword = value.toString();
  const firstHalf = encryptedPassword.slice(0, encryptedPassword.length / 2);
  const secondHalf = encryptedPassword.slice(
    encryptedPassword.length / 2,
    encryptedPassword.length,
  );

  const decryptedFirstHalf = firstHalf.split('').reverse().join('');
  const decryptedSecondHalf = secondHalf.split('').reverse().join('');

  const decryptedPassword =
    decryptedFirstHalf.toString() + decryptedSecondHalf.toString();

  return decryptedPassword;
};
export function formatNumberWithCommas(number) {
  return number.toLocaleString();
}

export function formatStatValue(value) {
  if (value >= 1000) {
    let suffix = '';
    let divisor = 1;

    if (value < 1000000) {
      suffix = 'K';
      divisor = 1000;
    } else if (value < 1000000000) {
      suffix = 'M';
      divisor = 1000000;
    } else if (value < 1000000000000) {
      suffix = 'B';
      divisor = 1000000000;
    } else {
      suffix = 'T';
      divisor = 1000000000000;
    }

    return (value / divisor).toFixed(1) + suffix;
  }

  return value.toString();
}

export function capitalizeString(str) {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
}

export const getCalendarGroupColor = (color) => {
  let lowerColor = color ? color.toLowerCase() : 'unknown';

  switch (lowerColor) {
    case 'white':
      return '#ffffff';

    case 'green':
      return '#0dab5d';

    case 'blue':
      return '#1cb1ee';

    case 'yellow':
      return 'yellow';

    default:
      return 'white';
  }
};
