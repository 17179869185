import { Loader, Stack, Text } from '@mantine/core';
import React from 'react';

const PageLoader = ({ mah }) => {
  return (
    <div>
      <Stack
        sx={{
          minHeight: mah || '50vh',
        }}
        justify="center"
        align="center"
        my={15}>
        <Loader size="xl" />
        <Text c="dimmed">Loading...</Text>
      </Stack>
    </div>
  );
};

export default PageLoader;
