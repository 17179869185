import React, { useEffect } from 'react';

const PAGE_SIZE = 20;

const PAGE_TITLE = 'FMD Reports';

const FmdReports = () => {
  useEffect(() => {
    document.title = 'FMD Reports | Montage';
  }, []);

  return <div>FmdReports</div>;
};

export default FmdReports;
