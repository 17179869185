import { Navigate } from 'react-router-dom';

const withProtection = (
  Component,
  isLoggedIn,
  checkSuperAdmin = false,
  role,
) => {
  if (checkSuperAdmin) {
    if (role == 'SUPER ADMIN') {
      return Component;
    }

    return <Navigate to="/" replace />;
  }

  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return Component;
};

export default withProtection;
