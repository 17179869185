import { Box, Button, Loader, Paper, Stack, Text } from '@mantine/core';
import { IconDownload, IconLoader, IconMoodSad2 } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { fetchAllBuilds } from 'api/appBuild';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';
import { NotificationUtil } from 'utils/notifications';
import { handleErrorResponse } from 'utils/utils';

const DownloadApp = () => {
  const [captchaSolvedToken, setcaptchaSolvedToken] = useState(null);

  const [release, setRelease] = useState(true);
  const [noAppBuildFound, setNoAppBuildFound] = useState(false);

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadImage = (fileName) => {
    setIsDownloading(true);

    fetch(`${process.env.REACT_APP_SERVER_URL}${fileName}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName.split('/').at(-1);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        setIsDownloading(false);
      })
      .catch((error) => {
        //console.error('Error downloading image:', error);
        setIsDownloading(false);
      });
  };

  const { data, isLoading, refetch, error, isFetching } = useQuery({
    queryKey: ['fetch-builds', null, null, null, null, release],
    queryFn: (values) => fetchAllBuilds(values),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    enabled: captchaSolvedToken ? true : false,
    onError: (error) => {
      if (error?.request?.status == 400) {
        setNoAppBuildFound(true);
      } else {
        handleErrorResponse(error);
      }
    },
  });

  useEffect(() => {
    document.title = 'Download App | Montage';
  }, []);

  return (
    <Stack
      align="center"
      justify="center"
      sx={{
        height: '100%',
      }}>
      <Paper p="lg" radius="xs" shadow="md">
        <Stack
          sx={{
            width: 310,
          }}>
          <Box
            component="img"
            src="/logo.png"
            sx={{
              width: '35%',
              alignSelf: 'center',
              objectFit: 'contain',
            }}
          />
          <Text align="center">Download the MONTAGE app</Text>

          <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
            onChange={(token) => setcaptchaSolvedToken(token)}
          />

          {captchaSolvedToken ? (
            !noAppBuildFound ? (
              <Link
                p="8px 15px"
                textAlign="center"
                fontSize="md"
                fontWeight="bold"
                rounded="md"
                mt="15px"
                w="100%"
                bgColor="teal"
                color="white"
                _hover={{ bgColor: 'green.500' }}
                download={true}
                onClick={() => handleDownloadImage(data?.inRelease?.appUrl)}>
                <Stack
                  justify="center"
                  align="center"
                  sx={{ backgroundColor: '#228be6', padding: '8px 15px' }}>
                  {isDownloading ? (
                    <Loader size="md" color="white" py="8px" />
                  ) : (
                    <IconDownload color="white" />
                  )}
                </Stack>
              </Link>
            ) : (
              <Button
                p="8px 15px"
                textAlign="center"
                fontSize="md"
                fontWeight="bold"
                rounded="md"
                //mt="15px"
                w="100%"
                bgColor="teal"
                _hover={{ bgColor: 'green.500' }}
                rightIcon={<IconMoodSad2 />}
                color="red"
                //download={true}
                onClick={() => {
                  NotificationUtil({
                    success: false,
                    title: 'No Build Found',
                    message: 'No Deployed Build Found',
                  });
                }}>
                No App Build Found!
              </Button>
            )
          ) : (
            <Text align="center" fz="sm" c="dimmed">
              Solve captcha to download
            </Text>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default DownloadApp;
