export const SKU_PAGE_URL = '/settings/skus';

export const MATERIAL_PAGE_URL = '/settings/materials';

export const CONTAINER_PAGE_URL = '/settings/containers';

export const MACHINE_PAGE_URL = '/settings/machines';

export const USER_PAGE_URL = '/settings/users';

export const BLEND_AND_FILTER_PAGE_URL = '/settings/blends-and-filters';

export const APP_DISTRIBUTION_PAGE_URL = '/settings/mobile-applications';

export const SHIFT_CALENDER_PAGE_URL = '/settings/shift-calender';

export const ACTION_LOGS_PAGE_URL = '/settings/action-logs';

export const REPORTS_PAGE_URL = '/reports';
export const REPORTS_PMD_URL = '/reports/pmd-reports';
export const REPORTS_FMD_URL = '/reports/fmd-reports';
