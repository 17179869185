//REDUX HAS NOT BEEN USED FOR AUTHENTICATION

//CONTEXT API HAS BEEN USED

//SEE auth-context

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appLoaded: false,
  isLoggedIn: false,
  user: null,
  token: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signin: (state, action) => {
      state.appLoaded = true;
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    refresh: (state, action) => {
      state.token = action.payload.token;
    },
    clearAllState: (state) => {
      state.appLoaded = false;
      state.isLoggedIn = false;
      state.user = null;
      state.token = null;
    },
    logout: (state) => {
      state.appLoaded = false;
      state.isLoggedIn = false;
      state.token = null;
      state.user = null;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
