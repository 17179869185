import { useEffect } from 'react';
import nProgress from 'nprogress';
import { Skeleton } from '@mantine/core';
import { useLocation } from 'react-router-dom';

const noLayoutRoutes = ['/', '/download-app'];

const ProgressLoader = () => {
  const location = useLocation();

  useEffect(() => {
    nProgress.start();

    return () => {
      nProgress.done();
    };
  }, []);

  if (noLayoutRoutes.includes(location.pathname)) {
    return null;
  }

  return (
    <div>
      <Skeleton height={50} mb="xl" width="15%" />
      <Skeleton height={300} mb="xl" />
    </div>
  );
};

export default ProgressLoader;
